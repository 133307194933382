.upload_grid {
	display: grid;
	grid-template-columns: minmax(6em, max-content) max-content minmax(10em, max-content);
}

.upload_grid > thead, .upload_grid > thead > tr, .upload_grid > tr {
	display: contents;
}

.upload_grid td {
	border: 1px solid;
}

.upload_entry:hover > *, .upload_entry:hover a {
	background-color: Highlight;
	color: HighlightText;
}

.upload_entry .name {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-right: 1em;
}

.upload_entry .size {
	padding-right: 0.5em;
	padding-left: 0.5em;
}

.upload_entry .link {
	direction: rtl;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}